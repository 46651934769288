/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "assets/img/expo-revestir/icons/close-icon.svg";
import UsuarioSemFoto from "assets/img/expo-revestir/icons/usuario-sem-foto.svg";
import Headroom from "headroom.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { AppContext } from "store";
import { userHasAccess, userProfiles } from "utils/permission-user";
import ExpoLogoMenuMobile from "../../assets/img/expo-revestir/logo-250x250px.png";
import MenuHamburguer from "../../assets/img/expo-revestir/icons/menu-hamburguer.svg";
import ExpoLogoWhite from "../../assets/img/expo-revestir/logo-200x200px.png";
import ExpoLogo from "../../assets/img/expo-revestir/logo-200x200px.png";
import CampoCustomizadoItem from "../../services/campo-customizado-item.service";
import ModalEditarUsuario from "./ModalEditarUsuario";

function ExpoNavBar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [campoCustomizado, setCampoCustomizado] = useState([]);
  const [showDialogProfile, setShowDialogProfile] = useState(false);
  const { t, i18n } = useTranslation();
  const { user } = React.useContext(AppContext);
  const time = new Date().getTime();

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
  });

  useEffect(() => {
    async function init() {
      const _campoCustomizadoService = new CampoCustomizadoItem();
      const campoCustomizadoResponse = await _campoCustomizadoService.listSub(
        1
      );
      setCampoCustomizado(campoCustomizadoResponse);
    }

    paginaAtual();

    init();
  }, [user, i18n.language]);

  const itensNaNavArray = [
    user.perfil?.id === 2 ? "expositores" : "home",
    "expositores",
    "programacao-eventos",
    "contato",
  ];

  function paginaAtual() {
    const path = window.location.pathname.substring(1);

    itensNaNavArray.forEach((item) => {
      if (item === path) {
        document.getElementById(path).style.textDecoration = "underline";
      } else {
        if (!userHasAccess([userProfiles.expositor])) {
          document.getElementById(item).style.textDecoration = "none";
        }
      }
    });
  }

  var modal = document.getElementById("myModal");

  function abreModal() {
    modal.style.display = "block";
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.getElementById("myModal").className = "hidden-menu-abre";
  }

  function fechaModal() {
    modal.style.display = "none";
    document.body.style.overflow = "auto";
    document.body.style.position = "static";
    document.getElementById("myModal").className = "hidden-menu-fecha";
  }

  return (
    <>
      <ModalEditarUsuario
        modalAberto={showDialogProfile}
        setModalAberto={setShowDialogProfile}
        isList={false}
      />

      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
            collapseOpen();
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container style={{ justifyContent: "center" }} id="nav-desk">
          <div>
            <h1>{campoCustomizado[0]?.item}</h1>
          </div>
        </Container>
        <div className="nav-itens-container">
          <div className="navbar-desktop">
            <Container>
              <NavbarBrand
                id="navbar-brand"
                to="/index"
                tag={Link}
                style={{ backgroudColor: "red" }}
              >
                {user?.perfil?.imagem ? (
                  <img src={`${process.env.REACT_APP_API_URL}usuarios/perfis/${user?.perfil?.id}/display`} alt=""></img>
                ) : (
                  <img src={ExpoLogo} alt=""></img>
                )}
              </NavbarBrand>
              <Row>
                <Col sm="6" md="6" lg="6">
                  <Col>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                        nav
                        role="button"
                      >
                        {i18n.language === "pt-BR" && t("Português")}
                        {i18n.language === "es" && t("Espanhol")}
                        {i18n.language === "en" && t("Inglês")}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-warning" right>
                        <DropdownItem
                          onClick={() => i18n.changeLanguage("pt-BR")}
                        >
                          {t("Português")}
                        </DropdownItem>
                        <DropdownItem onClick={() => i18n.changeLanguage("en")}>
                          {t("Inglês")}
                        </DropdownItem>
                        <DropdownItem onClick={() => i18n.changeLanguage("es")}>
                          {t("Espanhol")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col>
                    <ul className="social-media-container">
                      <li>
                        <a
                          href="https://www.facebook.com/exporevestir"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-facebook" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/exporevestir_oficial/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-instagram" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://br.pinterest.com/exporevestir/_created/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-pinterest" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/user/ANFACER"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Col>
                <Col sm="6" md="6" lg="6">
                  <Nav navbar>
                    {![4, 5].includes(user.perfil?.id) && (
                      <>
                        <NavItem
                          onClick={() => {
                            paginaAtual();
                          }}
                        >
                          <NavLink to="/home" tag={Link} id="home">
                            {t("Home")}
                          </NavLink>
                        </NavItem>

                        <NavItem
                          onClick={() => {
                            paginaAtual();
                          }}
                        >
                          <NavLink
                            to={"/expositores"}
                            tag={Link}
                            id="expositores"
                          >
                            {t("Expositores")}
                          </NavLink>
                        </NavItem>

                        <NavItem
                          onClick={() => {
                            paginaAtual();
                          }}
                        >
                          <NavLink
                            to="/programacao-eventos"
                            tag={Link}
                            id="programacao-eventos"
                          >
                            {t("Programação")}
                          </NavLink>
                        </NavItem>

                        <NavItem
                          onClick={() => {
                            paginaAtual();
                          }}
                        >
                          <NavLink to="/contato" tag={Link} id="contato">
                            {t("Contato")}
                          </NavLink>
                        </NavItem>
                      </>
                    )}

                    <NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                          data-toggle="dropdown"
                          tag={NavLink}
                          style={{ padding: "0" }}
                        >
                          <div className="usuario-foto-container">
                            <img
                              className="usuario-foto"
                              src={
                                user.imageFileName
                                  ? `${process.env.REACT_APP_API_URL}usuarios/${user.id}/display?${user.imageFileName})`
                                  : `${UsuarioSemFoto}`
                              }
                              alt="foto de perfil"
                            />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-info dropdown-perfil"
                          right
                        >
                          {!userHasAccess([
                            userProfiles.expositor,
                            userProfiles.assentador,
                          ]) && (
                            <DropdownItem to="/perfil" tag={Link}>
                              {t("Área do visitante")}
                            </DropdownItem>
                          )}
                          {userHasAccess([userProfiles.assentador]) && (
                            <DropdownItem
                              onClick={() => setShowDialogProfile(true)}
                            >
                              {t("Perfil")}
                            </DropdownItem>
                          )}
                          <DropdownItem divider />
                          <DropdownItem to="/sair" tag={Link}>
                            {t("Sair")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="navbar-mobile">
            <div className="menu-mobile">
              <Container>
                <Row>
                  <Col className="col-6">
                    <img src={ExpoLogoMenuMobile} alt=""></img>
                  </Col>
                  <Col className="col-6 menu-mobile-click">
                    <button
                      className="menu-mobile-click"
                      id="myBtn"
                      onClick={abreModal}
                    >
                      <p>Menu</p>
                      <img src={MenuHamburguer} alt=""></img>
                    </button>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
                <img src={ExpoLogoWhite} alt=""></img>
              </NavbarBrand>
              <div>
                <h1>{campoCustomizado[0]?.item}</h1>
              </div>
            </Container>
          </div>
        </div>
      </Navbar>
      <div id="myModal">
        <Container>
          <Nav navbar>
            <Row>
              <Col xs="8" className="col-10">
                <NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      data-toggle="dropdown"
                      tag={NavLink}
                      style={{ padding: "0" }}
                    >
                      <div className="profile-photo-small">
                        <img
                          alt="..."
                          className="img-circle img-responsive img-no-padding"
                          src={
                            user.imageFileName
                              ? `${process.env.REACT_APP_API_URL}usuarios/${user.id}/display?${time})`
                              : `${UsuarioSemFoto}`
                          }
                        />
                      </div>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/perfil"
                    tag={Link}
                    onClick={() => {
                      fechaModal();
                    }}
                  >
                    {t("Área do visitante")}
                  </NavLink>
                </NavItem>

                {!userHasAccess([userProfiles.expositor]) && (
                  <NavItem
                    onClick={() => {
                      fechaModal();
                    }}
                  >
                    <NavLink to="/home" tag={Link} id="home">
                      {t("Home")}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem
                  onClick={() => {
                    fechaModal();
                  }}
                >
                  <NavLink to={"/expositores"} tag={Link} id="expositores">
                    {t("Expositores")}
                  </NavLink>
                </NavItem>

                {!userHasAccess([userProfiles.expositor]) && (
                  <NavItem
                    onClick={() => {
                      fechaModal();
                    }}
                  >
                    <NavLink
                      to="/programacao-eventos"
                      tag={Link}
                      id="programacao-eventos"
                    >
                      {t("Programação")}
                    </NavLink>
                  </NavItem>
                )}

                {!userHasAccess([userProfiles.expositor]) && (
                  <NavItem
                    onClick={() => {
                      fechaModal();
                    }}
                  >
                    <NavLink to="/contato" tag={Link} id="contato">
                      {t("Contato")}
                    </NavLink>
                  </NavItem>
                )}

                <NavItem
                  onClick={() => {
                    fechaModal();
                  }}
                >
                  <NavLink to="/sair" tag={Link} id="sair">
                    {t("Sair")}
                  </NavLink>
                </NavItem>

                <ul className="social-media-container">
                  <li>
                    <a
                      href="https://www.facebook.com/exporevestir"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa fa-facebook" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/exporevestir_oficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa fa-instagram" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://br.pinterest.com/exporevestir/_created/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa fa-pinterest" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/user/ANFACER"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
                <UncontrolledDropdown
                  nav
                  inNavbar
                  style={{
                    display: "inline-block",
                  }}
                >
                  <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    role="button"
                  >
                    {i18n.language === "pt-BR" && t("Português")}
                    {i18n.language === "es" && t("Espanhol")}
                    {i18n.language === "en" && t("Inglês")}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-warning" right>
                    <DropdownItem onClick={() => i18n.changeLanguage("pt-BR")}>
                      {t("Português")}
                    </DropdownItem>
                    <DropdownItem onClick={() => i18n.changeLanguage("en")}>
                      {t("Inglês")}
                    </DropdownItem>
                    <DropdownItem onClick={() => i18n.changeLanguage("es")}>
                      {t("Espanhol")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col xs="2" className="col-2">
                <button
                  style={{
                    textAlign: "right",
                    padding: "0.5rem 0.5rem",
                    border: "0",
                    backgroundColor: "transparent",
                  }}
                  onClick={fechaModal}
                >
                  <img src={CloseIcon} alt="Fechar menu"></img>
                </button>
              </Col>
            </Row>
          </Nav>
        </Container>
      </div>
    </>
  );
}

export default ExpoNavBar;
