/* eslint-disable react-hooks/exhaustive-deps */
import CardAulas from "components/CardAulas";
import BannersPublicidade from "components/expo-revestir/BannersPublicidade";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CursosService from "services/cursos.service";
import { AppContext } from "store";
import { ordenarCursoPorDestaque } from "utils/ordenar-curso-por-destaque";
import QueryOptions from "utils/query-options";
import LogoAssentadorAnfacer from "../../assets/img/expo-revestir/logo-assentador-anfacer.png";
import "./style.scss";

function PageListCursos() {
  const [cardsToShow, setCardsToShow] = useState(99);
  const [cursos, setCursos] = useState([]);
  const [cursosAtualizados, setCursosAtualizados] = useState([]);
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { user } = useContext(AppContext);

  const handleVerMaisClick = () => {
    setCardsToShow(cardsToShow + 3);
    history.push("/assentador-todos-cursos");
  };

  const handleCardClick = (id) => {
    history.push(`/cursos-modulo/${id}`);
  };

  useEffect(() => {
    const carregarCursos = async () => {
      try {
        const cursosService = new CursosService();

        let queryOptions = new QueryOptions([["matriculado", 1]]);
        const response = await cursosService.list(queryOptions);
        const idsCursosMatriculados = response.map((item) => item.id);

        setCursos(response.sort(ordenarCursoPorDestaque));

        queryOptions = new QueryOptions([
          ["recentemente_atualizado", 1],
          ["per_page", 10],
        ]);
        const responseAtualizados = await cursosService.list(queryOptions);
        const cursosAtualizados = responseAtualizados.filter(
          (item) => !idsCursosMatriculados.includes(item.id)
        );

        setCursosAtualizados(cursosAtualizados.sort(ordenarCursoPorDestaque));
      } catch (error) {
        console.error("Erro ao carregar cursos:", error);
      }
    };

    if (user.perfil.id <= 3) {
      history.push("/home");
    }

    carregarCursos();
  }, [i18n.language]);

  return (
    <>
      <div className="header-assentador">
        <div>Central do Assentador Cerâmico</div>
        <div className="logo-assentador-anfacer">
          <img
            src={LogoAssentadorAnfacer}
            alt="Logo Área do Assentador Cerâmico"
          />
        </div>
      </div>
      <div className="container-page">
        <div className="container-banner">
          <BannersPublicidade idAreaPublicidade={5} bannerHome={false} />
        </div>

        <div className="container-meu-aprendizado">
          <div className="title-button">
            <div className="button-list">
              <button onClick={handleVerMaisClick}>
                {t("Ver a lista completa")}
              </button>
            </div>
          </div>
        </div>
        <div className="container-cards">
          <h3>{t("Meu aprendizado")}</h3>
          <div className="container-cards-aulas">
            {cursos.map((curso) => (
              <CardAulas
                curso={curso}
                click={() => handleCardClick(curso.id)}
              />
            ))}
          </div>
        </div>
        <div className="container-novos">
          <h3>{t("Recentemente atualizados")}</h3>
          <div className="container-cards-aulas-novos">
            {cursosAtualizados.map((curso) => (
              <CardAulas
                curso={curso}
                click={() => handleCardClick(curso.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageListCursos;
